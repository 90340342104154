import React, { useEffect, useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './Sidebar';
import ServiceProvidersTable from './ServiceProvidersTable';
import AnalyticsComponent from './AnalyticsComponent'; // Add your Analytics component here
import SettingsComponent from './SettingsComponent'; // Add your Settings component here
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { auth, db } from './firebase';
import { signOut } from 'firebase/auth';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const libraries = ['places']; // Required for Autocomplete


const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const navigate=useHistory();
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Sign-out successful");
      navigate.push('/');
      // You can redirect the user to the login page or perform other actions after sign-out
    } catch (error) {
      console.log("An error occurred during sign-out: ", error.message);
    }
  };

  const SignOut=()=>{
    return(
      <div>
        <center>
          <div >
            <h1>Service Spotter</h1>
        <button style={{marginTop:150}} onClick={handleSignOut} className='btn btn-primary btn-sm'>SignOut</button>
        <p>{auth.currentUser?.email}</p>
        </div>
        </center>
      </div>
    );
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <div>Welcome to the Dashboard!</div>; // Placeholder for Dashboard content
      case 'serviceProviders':
        return <ServiceProvidersTable />;
      case 'analytics':
        return <AnalyticsComponent />;
      case 'settings':
        return <SettingsComponent />;
      case 'signout':
        return <SignOut/> 
      default:
        return <div>Welcome to the Dashboard!</div>;
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBc9E7rU3j1m5PdB0Xxf5mBXNWzR6pOI94" libraries={libraries}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar setActiveTab={setActiveTab} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {renderContent()}
      </Box>
    </Box>
    </LoadScript>

  );
};

export default Dashboard;
