// firebase.js
import { initializeApp } from '@firebase/app';
import { getToken as firebaseGetToken,getMessaging,onMessage } from '@firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAVSiuypKz7yQi1vAZf-LfGdIejtH5J9-g",
  authDomain: "service-spotter.firebaseapp.com",
  projectId: "service-spotter",
  storageBucket: "service-spotter.appspot.com",
  messagingSenderId: "732962752035",
  appId: "1:732962752035:web:37ddaac4c44b92544cd396",
  measurementId: "G-GBL3M7VGER"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);




export const getToken = (setTokenFound) => {
  return firebaseGetToken(messaging, { vapidKey: 'BN4Y4Y9LXRdRWcUnprlGWgF_9RcKmu-yilD92P2XMKmTZG1IknYx294P5bYXhK9gIzj3Jv-F0snusGiUQf6Cdp4' })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // Show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // Shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token: ', err);
      // Catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
