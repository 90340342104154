import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, LoadScript, DirectionsRenderer, Marker, Autocomplete, Circle } from "@react-google-maps/api";
import Scrollbar from 'react-scrollbar';
import './stylesServiceSpotter.css';
import ProfilePic1 from './profile1.jpg';
import ProfilePic2 from './profile2.jpg';
import ProfilePic3 from './profile3.jpg';
import mechanic1 from './mechanic1.jpg';
import mechanic2 from './mechanic2.jpeg';
import mechanice2 from './mechanice2.jpg';
import plumber1 from './plumber1.jpg';
import plumber2 from './plumber2.jpg';
import electrician1 from './electrician1.jpg';
import electrician2 from './eletrician2.jpg';
import { mapStyles } from "./stylesJS";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faComment, faSearch } from '@fortawesome/free-solid-svg-icons';

import L from 'leaflet';
import { onMessageListener } from './firebase';
import { Toast } from "react-bootstrap";
import register from './serviceWorker';
import { getToken } from "./firebase";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { FixedSizeList as List } from 'react-window';

const ContactList = ({ filteredContacts, handleContactClick }) => {
    const [deviceIsComputer, setDeviceIsComputer] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isIphone, setIsIphone] = useState(false);
    const [isTablet, setIsTablet] = useState(null);


    useEffect(() => {
        const loadStyles = async () => {
            const userAgent = navigator.userAgent.toLowerCase();

            try {
                if (userAgent.includes('iphone')) {
                    // Load iPhone-specific styles
                    await import('./iphoneStyles.css');
                    setIsIphone(true);
                    console.log('iPhone styles loaded');
                } else if (userAgent.includes('ipad') || userAgent.includes('tablet')) {
                    // Load tablet-specific styles
                    await import('./tabletStyles.css');
                    console.log('Tablet styles loaded');
                    setIsTablet(true);
                } else if (userAgent.includes('win') || userAgent.includes('mac')) {
                    // Load styles for Windows or macOS
                    await import('./stylesServiceSpotter.css');
                    console.log('Windows or macOS styles loaded');
                    setDeviceIsComputer(true);
                } else {
                    // Load Android or other device styles
                    await import('./androidIos.css');
                    setIsAndroid(true);
                    console.log('Device-specific styles loaded');
                }
            } catch (error) {
                console.error('Error loading styles:', error);
            }
        };

        loadStyles();
    }, []);


    const ContactItem = ({ index, style }) => {
        const contact = filteredContacts[index];

        return (
            <div key={contact.name} className="contact-buttons" style={style}>
                <button className="contact-button" onClick={() => handleContactClick(contact)}>
                    {contact.imageUrls && contact.imageUrls.length > 0 && (
                        <img
                            src={contact.imageUrls[0]} // Display only the first image
                            alt={`Provider Image ${index}`}
                            className="profile-pic"
                        />
                    )}
                    <div className="contact-info">
                        <div style={{ width: '140px' }} className="contact-name">{contact.name}</div>
                        <div className="contact-unit">{contact.qualification}</div>

                        {/* <div className="contact-unit">
                            ratings: <strong>{contact.ratings}</strong>
                        </div> */}
                    </div>
                </button>
                <hr />
            </div>
        );
    };
    if (isAndroid) {
        return (


            <List
                height={500} // Adjust height as necessary
                itemCount={filteredContacts.length}
                itemSize={100} // Adjust item size to fit your design
                width={'220px'}
                style={{ pointerEvents: 'auto', marginTop: 8 }}

            >
                {ContactItem}
            </List>
        );
    }
    else if (isIphone) {
        return (
            <List
                height={500} // Adjust height as necessary
                itemCount={filteredContacts.length}
                itemSize={100} // Adjust item size to fit your design
                width={'220px'}
                style={{ pointerEvents: 'auto', marginTop: 8 }}

            >
                {ContactItem}
            </List>
        );
    }
    else if (isTablet) {
        return (
            <List
                height={500} // Adjust height as necessary
                itemCount={filteredContacts.length}
                itemSize={100} // Adjust item size to fit your design
                width={'240px'}
                style={{ pointerEvents: 'auto', marginTop: 8 }}

            >
                {ContactItem}
            </List>
        );
    }
    else {
        return (
            <List
                height={500} // Adjust height as necessary
                itemCount={filteredContacts.length}
                itemSize={120} // Adjust item size to fit your design
                width={'330px'}
                style={{ pointerEvents: 'auto', marginTop: 8 }}

            >
                {ContactItem}
            </List>
        )
    }
};



const GalleryModal = ({ images, selectedIndex, onClose }) => {
    const [deviceIsComputer, setDeviceIsComputer] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isIphone, setIsIphone] = useState(false);
    const [isTablet, setIsTablet] = useState(null);

    useEffect(() => {
        const loadStyles = async () => {
            const userAgent = navigator.userAgent.toLowerCase();

            try {
                if (userAgent.includes('iphone')) {
                    // Load iPhone-specific styles
                    await import('./iphoneStyles.css');
                    setIsIphone(true);
                    console.log('iPhone styles loaded');
                } else if (userAgent.includes('ipad') || userAgent.includes('tablet')) {
                    // Load tablet-specific styles
                    await import('./tabletStyles.css');
                    console.log('Tablet styles loaded');
                    setIsTablet(true);
                } else if (userAgent.includes('win') || userAgent.includes('mac')) {
                    // Load styles for Windows or macOS
                    await import('./stylesServiceSpotter.css');
                    console.log('Windows or macOS styles loaded');
                    setDeviceIsComputer(true);
                } else {
                    // Load Android or other device styles
                    await import('./androidIos.css');
                    setIsAndroid(true);
                    console.log('Device-specific styles loaded');
                }
            } catch (error) {
                console.error('Error loading styles:', error);
            }
        };

        loadStyles();
    }, []);

    const [currentIndex, setCurrentIndex] = useState(selectedIndex);

    const showNextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const showPreviousImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
    if (isAndroid) {
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <button style={{ color: 'black' }} className="close-button" onClick={onClose}>
                        &times;
                    </button>
                    <img src={images[currentIndex]} alt={`Gallery Image ${currentIndex}`} className="modal-image" />
                    <div className="modal-navigation">
                        <button onClick={showPreviousImage} className="modal-nav-button">Previous</button>
                        <button onClick={showNextImage} className="modal-nav-button">Next</button>
                    </div>
                </div>
            </div>
        );
    }
    else if (isIphone) {
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <button style={{ color: 'black' }} className="close-button" onClick={onClose}>
                        &times;
                    </button>
                    <img src={images[currentIndex]} alt={`Gallery Image ${currentIndex}`} className="modal-image" />
                    <div className="modal-navigation">
                        <button onClick={showPreviousImage} className="modal-nav-button">Previous</button>
                        <button onClick={showNextImage} className="modal-nav-button">Next</button>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <button style={{ color: 'white' }} className="close-button" onClick={onClose}>
                        &times;
                    </button>
                    <img src={images[currentIndex]} alt={`Gallery Image ${currentIndex}`} className="modal-image" />
                    <div className="modal-navigation">
                        <button onClick={showPreviousImage} className="modal-nav-button">Previous</button>
                        <button onClick={showNextImage} className="modal-nav-button">Next</button>
                    </div>
                </div>
            </div>
        );
    }
};

const App = () => {
    const [deviceIsComputer, setDeviceIsComputer] = useState(null);
    const [setIsSearchedIconIsClicked, isSearchedIconIsClicked] = useState(null);
    const [isTablet, setIsTablet] = useState(false);
    const [showFullDisclaimer, setShowFullDisclaimer] = useState(false);

    const toggleDisclaimer = () => {
        setShowFullDisclaimer(!showFullDisclaimer);
    };

    useEffect(() => {
        const loadStyles = async () => {
            const userAgent = navigator.userAgent.toLowerCase();
            try {
                if (userAgent.includes('iphone')) {
                    // Load iPhone-specific styles
                    await import('./iphoneStyles.css');
                    setSearchIconIsClicked(true);
                    console.log('iPhone styles loaded');
                } else if (userAgent.includes('ipad air') || userAgent.includes('ipad') || userAgent.includes('tablet')) {
                    // Load tablet-specific styles
                    await import('./tabletStyles.css');
                    console.log('Tablet styles loaded');
                    setIsTablet(true);
                } else if (userAgent.includes('win') || userAgent.includes('mac')) {
                    // Load styles for Windows or macOS
                    await import('./stylesServiceSpotter.css');
                    console.log('Windows or macOS styles loaded');
                    setDeviceIsComputer(true);
                } else {
                    // Load Android or other device styles
                    await import('./androidIos.css');
                    setSearchIconIsClicked(true);
                    console.log('Device-specific styles loaded');
                }
            } catch (error) {
                console.error('Error loading styles:', error);
            }
        };

        loadStyles();
    }, []);


    const navigate = useHistory();

    register();
    const [currentLocation, setCurrentLocation] = useState({ lat: null, lng: null });
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [directions, setDirections] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [radius, setRadius] = useState(5000); // Default radius in meters
    const [showRadius, setShowRadius] = useState(false);
    const [contactIsClicked, setContactIsClicked] = useState(false);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [searchedLocation, setSearchedLocation] = useState(null);
    const [profilePic, setProfilePic] = useState(null);
    const [searchIconIsClicked, setSearchIconIsClicked] = useState(false);


    const [galleryOpen, setGalleryOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setGalleryOpen(true);
    };

    //Filters
    const [qualification, setQualification] = useState('');

    const [showFilters, setShowFilters] = useState(true);


    //Notification start here
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isTokenFound, setTokenFound] = useState(false);

    getToken(setTokenFound);
    onMessageListener().then(payload => {
        setShow(true);
        setNotification({ title: payload.notification.title, body: payload.notification.body });
        console.log(payload);
    }).catch(err => console.log('failed: ', err));

    //Notification ends here


    const [contacts, setContacts] = useState([]); // State to hold contacts from Firestore

    // Fetch contacts from Firestore
    useEffect(() => {
        const fetchContacts = async () => {
            const db = getFirestore(); // Initialize Firestore
            const contactsCollection = collection(db, "serviceProviders"); // Replace with your Firestore collection name
            const contactsSnapshot = await getDocs(contactsCollection);
            const contactsList = contactsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Map docs to data
            setContacts(contactsList); // Update state with fetched contacts
            setProfilePic(contactsList.imageUrl[0]);
        };

        fetchContacts().catch(error => console.error("Error fetching contacts: ", error));
    }, []); // Empty dependency array to run only once on mount

    // Update filterContacts to use the fetched contacts


    const qualifications = [
        "All",
        "Plumbing",
        "Catering",
        "Baking",
        "Construction",
        "Electrician",
        "Carpentry",
        "HVAC (Heating, Ventilation, and Air Conditioning)",
        "Landscaping and Gardening",
        "Roofing",
        "Painting and Decorating",
        "Appliance Repair",
        "Pest Control",
        "Cleaning Services (Home/Office)",
        "Window and Glass Repair",
        "Locksmithing",
        "Tiling and Flooring",
        "General Handyman",
        "Masonry",
        "Paving and Driveway Maintenance",
        "Waterproofing",
        "Swimming Pool Maintenance",
        "Welding and Metalwork",
        "Security System Installation",
        "Paving"
    ];



    const geocodeLatLng = useCallback((latLng, callback) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    callback(results[0].formatted_address);
                } else {
                    callback('No results found');
                }
            } else {
                console.error('Geocoder failed due to:', status);
                callback('Geocoder failed');
            }
        });
    }, []);

    const calculateDirections = useCallback(async (destination) => {
        const directionsService = new window.google.maps.DirectionsService();
        const origin = new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng);

        directionsService.route(
            {
                origin,
                destination: new window.google.maps.LatLng(destination.lat, destination.lng),
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error('Error calculating directions:', status);
                }
            }
        );
    }, [currentLocation]);
    // Only calculate filtered contacts when filter values or contacts change
    // Filter contacts by qualification whenever the qualification changes
    // Update this function to filter contacts based on qualification and location
    const filterContacts = useCallback(() => {
        if (selectedPlace) {
            const newFilteredContacts = contacts
                .map(contact => {
                    const distance = calculateDistance(
                        selectedPlace.lat,
                        selectedPlace.lng,
                        contact.location.lat,
                        contact.location.lng
                    );
                    return { ...contact, distance }; // Add distance to contact object
                })
                .filter(contact => {
                    // Check if the qualification is "All"
                    if (qualification === "All") {
                        return contact.distance <= radius; // Include all contacts within the radius
                    }
                    // Filter by distance and specific qualification
                    return contact.distance <= radius && contact.qualification.includes(qualification);
                })
                .sort((a, b) => a.distance - b.distance); // Sort by distance

            setFilteredContacts(newFilteredContacts);
        } else {
            setFilteredContacts([]); // Clear filtered contacts if no place is selected
        }
    }, [qualification, selectedPlace, radius, contacts]);



    useEffect(() => {
        filterContacts();
    }, [qualification, selectedPlace, radius, filterContacts]);


    const [error, setError] = useState(null);



    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;

                    // Update state with the fetched location
                    setCurrentLocation({ lat, lng });
                },
                (err) => {
                    console.error(err);
                    setError("Failed to fetch location. Please enable location services.");
                },
                { enableHighAccuracy: true } // Request higher accuracy
            );
        } else {
            setError("Geolocation is not supported by your browser.");
        }
    }, []);

    useEffect(() => {
        // Log the updated currentLocation when it changes
        if (currentLocation.lat !== null && currentLocation.lng !== null) {
            console.log("Updated Latitude: ", currentLocation.lat);
            console.log("Updated Longitude: ", currentLocation.lng);
        }
    }, [currentLocation]); // Watch for changes to the entire currentLocation object




    const handleContactClick = (contact) => {
        setContactIsClicked(true);
        setShowFilters(false);
        const latLng = new window.google.maps.LatLng(contact.location.lat, contact.location.lng);
        geocodeLatLng(latLng, (address) => {
            setSelectedPlace(contact.location);
            setSelectedContact({ ...contact, address });
            calculateDirections(contact.location);
        });
    };

    const onLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
        setContactIsClicked(false);
        setShowFilters(true);

        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                const latLng = new window.google.maps.LatLng(location.lat, location.lng);
                geocodeLatLng(latLng, (address) => {
                    setSelectedPlace(location);
                    setSelectedContact({ address });
                    setSearchedLocation(location); // Set the searched location here
                    setShowRadius(true);
                });
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };
    const imageUrl = contacts.imageUrl && Array.isArray(contacts.imageUrl) && contacts.imageUrl.length > 0 ? contacts.imageUrl[0] : 'default-image-url'; // Fallback URL if imageUrl[0] is not available

    console.log('Profile Pic ' + imageUrl);


    const calculateDistance = (lat1, lng1, lat2, lng2) => {
        const R = 6371e3; // Radius of the Earth in meters
        const φ1 = (lat1 * Math.PI) / 180;
        const φ2 = (lat2 * Math.PI) / 180;
        const Δφ = ((lat2 - lat1) * Math.PI) / 180;
        const Δλ = ((lng2 - lng1) * Math.PI) / 180;

        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in meters
    };


    // Function to toggle the filter dropdown
    const toggleFilters = () => {
        setShowFilters(false);
    };



    function formatPhoneNumber(phoneNumber) {
        // Example: Format the phone number as (123) 456-7890
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
      


    const showOnlyDirection = () => {
        setShowRadius(false);
    }

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (!event.target.closest('.filter-options')) {
    //             setShowFilters(false);
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    const handleChatClick = () => {
        if (selectedContact) {
            const phoneNumber = selectedContact.phoneNumber; // Ensure `selectedContact` has a `phone` property
            const message = "Hello!"; // Optional initial message
            const encodedMessage = encodeURIComponent(message);

            // Redirect to WhatsApp
            window.open(`https://wa.me/${phoneNumber}?text=${encodedMessage}`, '_blank');
        } else {
            alert("Please select a contact first.");
        }
    };

    //Icon Marker
    const customIcon = L.icon({
        iconUrl: "./myLogo.jpg", // Replace with the path to your PNG file
        iconSize: [32, 32], // Adjust the size of the icon
        iconAnchor: [16, 32], // Anchor point of the icon (usually the tip of the marker)
        popupAnchor: [0, -32] // Position of the popup relative to the icon
    });
    //-----------
    const heightInPixels = window.innerHeight; // Equivalent to 100vh
    const widthInPixels = window.innerWidth; // Equivalent to 100%

    console.log(`Height: ${heightInPixels}px`);
    console.log(`Width: ${widthInPixels}px`);




    return (
        <LoadScript libraries={["places"]} googleMapsApiKey="AIzaSyBc9E7rU3j1m5PdB0Xxf5mBXNWzR6pOI94">
            <div id="map-container">
                <div className="overlay">
                    <div className="servicespotter-text">
                        {searchIconIsClicked ?
                            <></>
                            :
                            <h1>ServiceSpotter</h1>
                        }
                        <img className="myLogo" src="./myLogo.jpg" />
                        {deviceIsComputer ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* Notification icon */}
                                <FontAwesomeIcon style={{ display: 'none' }} onClick={() => setShow(true)} icon={faBell} id="iconBell" />
                                <Link to='/login' id="admin" className='btn btn-dark btn-sm'>Admin</Link>
                                <center>
                                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                        <input
                                            id="searchByLocation"
                                            type="search"
                                            className="search"
                                            placeholder="Search by location..."
                                        />
                                    </Autocomplete>
                                </center>
                            </div>
                            :
                            <div className="search-filter-container" style={{ display: 'flex', alignItems: 'center' }}>
                                {/* Search toggle */}
                                <div className="search-container">
                                    {/* FontAwesome Search Icon */}
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className="search-icon"

                                    />
                                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                        {/* Search input, visible by default */}
                                        <input
                                            id="searchByLocation"
                                            type="search"
                                            className="search"
                                            placeholder="Search by location..."
                                        />
                                    </Autocomplete>
                                </div>
                            </div>

                        }


                        {/* <p style={{ position: 'fixed', width: '90%', marginTop: -1, marginLeft: -500, fontSize: '15px' }}><small>{selectedContact ? selectedContact.address : <></>}</small></p> */}


                    </div>
                    <br />
                    {showRadius ?
                        <button onClick={showOnlyDirection} style={{ pointerEvents: 'auto' }} className="btn btn-dark btn-sm">Remove radius</button>
                        : <p></p>}
                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
                        position: 'absolute',
                        top: 95,
                        right: 420,
                        minWidth: 200
                    }}>
                        <Toast.Header>
                            <img
                                src="holder.js/20x20?text=%20"
                                className="rounded mr-2"
                                alt=""
                            />
                            <strong className="mr-auto">{notification.title}</strong>
                        </Toast.Header>
                        <Toast.Body>{notification.body}</Toast.Body>
                    </Toast>
                    <ContactList filteredContacts={filteredContacts} handleContactClick={handleContactClick} />
                    {selectedContact && contactIsClicked && (
                        <div style={{ zIndex: 1 }} className="contact-profile">
                            <button
                                className="close-button"
                                onClick={() => [setContactIsClicked(false), setShowFilters(true)]} // Close the contact profile
                                style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    background: 'transparent',
                                    border: 'none',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                    color: 'black'
                                }}
                            >
                                &times;
                            </button>
                            <center>


                                <h2 style={{ fontSize: 20 }}>{selectedContact.name}</h2>

                                <div className="contact-details">
                                    <div className="contact-unit">{selectedContact.qualification}</div>
                                    <div className="contact-unit">
                                    <a style={{textDecoration:'none'}}  href={`tel:${selectedContact.phoneNumber}`}>
                                            {selectedContact.phoneNumber}
                                    </a>
                                    </div>
                                    <div>
                                        <a className="contact-unit" style={{ textDecoration: 'none' }} href={`mailto:${selectedContact.email}`} target="_blank" rel="noopener noreferrer">
                                            {selectedContact.email}
                                        </a>
                                    </div>

                                    <div className="contact-unit">
                                        <small><a style={{ color: 'darkblue', fontSize: '10px', textDecoration: 'none' }} href={`mailto:${selectedContact.website}`} target="_blank" rel="noopener noreferrer">
                                            {selectedContact.website}
                                        </a>
                                        </small>
                                    </div>
                                    <div className="contact-unit">
                                        <FontAwesomeIcon
                                            color="green"
                                            icon={faComment}
                                            style={{ fontSize: '20px', cursor: 'pointer' }}
                                            onClick={handleChatClick} // Attach the click handler here
                                        />
                                    </div>
                                </div>

                                <div className="gallery-section">
                                    <h3 className="contact-unit">Gallery</h3>
                                    <div className="gallery-images">
                                        {selectedContact.gallery?.map((img, index) => (
                                            <img key={index} src={img} className="gallery-pic" />
                                        ))}

                                        {selectedContact && selectedContact.imageUrls && (
                                            <div className="gallery">
                                                {selectedContact.imageUrls.map((imageUrl, index) => (
                                                    <img
                                                        key={index}
                                                        src={imageUrl}
                                                        alt={`Provider Image ${index}`}
                                                        className="gallery-pic"
                                                        onClick={() => handleImageClick(index)}
                                                    />
                                                ))}
                                            </div>
                                        )}

                                        {galleryOpen && (
                                            <GalleryModal
                                                images={selectedContact.imageUrls}
                                                selectedIndex={selectedImageIndex}
                                                onClose={() => setGalleryOpen(false)}
                                            />
                                        )}

                                    </div>
                                </div>
                            </center>

                            <div className="reviews-section">
                                <center>
                                    {/* <p className="contact-unit">
                                        Rating: <small style={{ fontWeight: 'bold' }}>{selectedContact.ratings} / 5</small>
                                    </p> */}
                                    <h3 className="contact-unit">Disclaimer</h3>
                                    <p style={{ fontSize: 11, width: '80%' }}>
                                        ServiceSpotter provides a platform for connecting service providers and customers.
                                        {showFullDisclaimer ? (
                                            <>
                                                The information, photos, and work displayed on this site are provided by the service providers themselves and are not verified by ServiceSpotter.
                                                We do not take responsibility for the accuracy of this information or the quality of the work performed.
                                                Users are encouraged to conduct their own due diligence before engaging with any service provider listed on our site.
                                            </>
                                        ) : (
                                            <> The information displayed on this site is provided by service providers. </>
                                        )}
                                    </p>
                                    <button
                                        style={{ fontSize: 12, color: 'blue', background: 'none', border: 'none', cursor: 'pointer' }}
                                        onClick={toggleDisclaimer}
                                    >
                                        {showFullDisclaimer ? 'Read Less' : 'Read More'}
                                    </button>
                                </center>
                            </div>

                            <div className="comments-section">
                                {/* <center>
                                    <h3 className="contact-unit">Comments</h3>
                                </center>
                                <ul>
                                    {selectedContact.comments?.map((comment, index) => (
                                        <li key={index}>{comment}</li>
                                    ))}
                                </ul> */}
                            </div>
                        </div>
                    )}
                    {showFilters && (

                        <div className="filter-options">
                            <div className="filter-container">
                                <label>Filter Service by</label>
                                <select
                                    id="qualification"
                                    value={qualification}
                                    onChange={(e) => setQualification(e.target.value)}
                                    className="filter-select"
                                >
                                    <option value="">Select a Service</option>

                                    {qualifications.map((qual) => (
                                        <option key={qual} value={qual}>
                                            {qual}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>



                    )}


                </div>
                {searchedLocation ?
                    <GoogleMap
                        mapContainerStyle={{ height: "100vh", width: "100%" }}

                        center={searchedLocation}
                        zoom={14}
                        options={{
                            styles: mapStyles,
                            mapTypeControl: false,
                        }}
                    >
                        {currentLocation && (
                            <Marker icon={customIcon} position={currentLocation} />
                        )}
                        {selectedPlace && (
                            <Marker icon={customIcon} position={selectedPlace} />
                        )}
                        {filteredContacts.map((contact) => (
                            <Marker
                                key={contact.name}
                                position={contact.location}
                                icon={customIcon}
                            />
                        ))}
                        {showRadius && (
                            <Circle
                                center={searchedLocation}
                                radius={radius}
                                options={{
                                    strokeColor: '#00C000',
                                    strokeOpacity: 0.9,
                                    strokeWeight: 2,
                                    fillColor: 'rgba(255, 87, 51, 0.2)',
                                    fillOpacity: 0.9,
                                }}
                            />
                        )}
                        {directions && <DirectionsRenderer directions={directions} />}
                    </GoogleMap>
                    :
                    <GoogleMap
                        mapContainerStyle={{ height: "100vh", width: "100%" }}

                        center={currentLocation}
                        zoom={14}
                        options={{
                            styles: mapStyles,
                            mapTypeControl: false,
                        }}
                    >
                        {currentLocation && (
                            <Marker icon={customIcon} position={currentLocation} />
                        )}
                        {selectedPlace && (
                            <Marker icon={customIcon} position={selectedPlace} />
                        )}
                        {filteredContacts.map((contact) => (
                            <Marker
                                key={contact.name}
                                position={contact.location}
                                icon={customIcon}
                            />
                        ))}
                        {showRadius && (
                            <Circle
                                center={searchedLocation}
                                radius={radius}
                                options={{
                                    strokeColor: '#00C000',
                                    strokeOpacity: 0.9,
                                    strokeWeight: 2,
                                    fillColor: 'rgba(255, 87, 51, 0.2)',
                                    fillOpacity: 0.9,
                                }}
                            />
                        )}
                        {directions && <DirectionsRenderer directions={directions} />}
                    </GoogleMap>
                }
            </div>
        </LoadScript>
    );
};

export default App;