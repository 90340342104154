import React, { useState } from "react";
import { auth } from './dashboard/firebase'; // Import the auth object from your Firebase setup
import { signInWithEmailAndPassword } from 'firebase/auth';
import './authStyles.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useHistory(); // useNavigate hook for navigation

  const handleLogin = async (e) => {
    e.preventDefault();
    // Validation
    if (email === "" || password === "") {
      setError("Please fill in all fields.");
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Login successful!");
      // Save auth token to localStorage for the session
      const userToken = await auth.currentUser.getIdToken();
      localStorage.setItem('authToken', userToken);
      navigate.push('/home'); // Navigate to the Home page after successful login
    } catch (err) {
      setError("Login failed: " + err.message);
    }
  };

  return (
    <div className="auth-container">
      <div style={{float:'left'}}>
      <center>
      <img style={{width:'65%',height:'65%'}} src="./myLogo.jpg" /> 
      </center>
      </div>
      <div style={{float:'right'}} className="auth-box">
        <h2>Login</h2>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="auth-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
