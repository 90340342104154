import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import App from './App';
import LoginPage from './login';
import SignupPage from './signup';
import Dashboard from './dashboard/DashBoard';
import { auth } from './dashboard/firebase'; // Import Firebase auth
import ChatRoom from './ChatRoom';
import ChatStart from './ChatStart';

// Custom hook to check authentication status
const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false); // Once we know the auth state, stop loading
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  return { isAuthenticated, loading };
};

// ProtectedRoute Component
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    // You can show a loading spinner while checking authentication
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

class Main extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/signUp' component={SignupPage} />
          <Route exact path='/ChatStart' component={ChatStart} /> {/* Add chat route */}
          <Route exact path='/ChatRoom' component={ChatRoom} />
          <Route exact path='/' component={App} />
          <ProtectedRoute exact path='/home' component={Dashboard} />
        </Switch>
      </Router>
    );
  }
}

export default Main;


