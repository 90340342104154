// src/ChatRoom.js
import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from './dashboard/firebase';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import moment from 'moment'; // Import Moment.js

const ChatRoom = ({ contactId }) => {
    const [messages, setMessages] = useState([]);
    const [formValue, setFormValue] = useState('');
    const messagesEndRef = useRef(null);
    const servPUserId = `${contactId}${auth.currentUser?.uid}`;

    useEffect(() => {
        const messagesCollectionRef = collection(db, `serviceProviders/${servPUserId}/messages`);
        const q = query(messagesCollectionRef, orderBy('createdAt'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messagesArray = [];
            querySnapshot.forEach((doc) => {
                messagesArray.push({ id: doc.id, ...doc.data() });
            });
            setMessages(messagesArray);
        });

        return () => unsubscribe();
    }, [contactId]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const sendMessage = async (e) => {
        e.preventDefault();
        const { uid, photoURL } = auth.currentUser;
        await addDoc(collection(db, `serviceProviders/${servPUserId}/messages`), {
            text: formValue,
            createdAt: serverTimestamp(),
            uid,
            photoURL,
        });
        setFormValue('');
    };

    const ChatMessage = ({ message }) => {
        const { text, uid, photoURL, createdAt } = message;
        const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
        const formattedTime = moment(createdAt?.toDate()).format('MMMM Do YYYY, h:mm A'); // Format date and time

        return (
            <div style={{ ...styles.message, ...(messageClass === 'sent' ? styles.sentMessage : styles.receivedMessage) }}>
                {photoURL && (
                    <img
                        src={photoURL}
                        alt="Avatar"
                        style={styles.avatar}
                    />
                )}
                <div>
                    <p style={styles.messageText}>{text}</p>
                    <span style={styles.timestamp}>{formattedTime}</span> {/* Display formatted time */}
                </div>
            </div>
        );
    };

    return (
        <div style={styles.chatContainer}>
            <div style={styles.messagesContainer}>
                {messages.map(msg => (
                    <ChatMessage key={msg.id} message={msg} />
                ))}
                <div ref={messagesEndRef} />
            </div>
            <form onSubmit={sendMessage} style={styles.form}>
                <input
                    value={formValue}
                    onChange={(e) => setFormValue(e.target.value)}
                    placeholder="Type your message..."
                    style={styles.input}
                />
                <button type="submit" style={styles.sendButton}>Send</button>
            </form>
        </div>
    );
};

export default ChatRoom;

const styles = {
    chatContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        backgroundColor: '#f4f4f8',
    },
    messagesContainer: {
        flex: 1,
        padding: '20px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        paddingBottom: '60px',
    },
    form: {
        display: 'flex',
        padding: '10px',
        borderTop: '1px solid #ddd',
        backgroundColor: '#ffffff',
        position: 'sticky',
        bottom: 0,
        zIndex: 10,
    },
    input: {
        flex: 1,
        padding: '10px',
        borderRadius: '20px',
        border: '1px solid #ccc',
        marginRight: '10px',
        outline: 'none',
        fontSize: '16px',
    },
    sendButton: {
        padding: '10px 20px',
        borderRadius: '20px',
        backgroundColor: '#4a90e2',
        color: 'white',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    message: {
        display: 'flex',
        alignItems: 'flex-end',
        maxWidth: '70%',
        padding: '10px',
        borderRadius: '15px',
        fontSize: '16px',
    },
    sentMessage: {
        alignSelf: 'flex-end',
        backgroundColor: '#4a90e2',
        color: 'white',
    },
    receivedMessage: {
        alignSelf: 'flex-start',
        backgroundColor: '#e0e0e0',
        color: 'black',
    },
    avatar: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginRight: '10px',
    },
    messageText: {
        margin: 0,
    },
    timestamp: {
        fontSize: '12px',
        color: 'white', // Style for timestamp
        marginTop: '5px',
    },
};
