import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import LogoutIcon from '@mui/icons-material/Logout';

const Sidebar = ({ setActiveTab }) => {
  const drawerWidth = 110;

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <List>
        <ListItem button onClick={() => setActiveTab('dashboard')}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText  primary="" />
        </ListItem>
        <ListItem button onClick={() => setActiveTab('serviceProviders')}>
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText primary="" />
        </ListItem>
        <ListItem button onClick={() => setActiveTab('analytics')}>
          <ListItemIcon><BarChartIcon /></ListItemIcon>
          <ListItemText primary="" />
        </ListItem>
        <ListItem button onClick={() => setActiveTab('settings')}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary="" />
        </ListItem>
        <ListItem button onClick={() => setActiveTab('signout')}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
