// src/ChatStart.js
import React, { useEffect, useState } from 'react';
import { auth, db } from './dashboard/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import ChatRoom from './ChatRoom';
import { useLocation } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import './chatStart.css'; // Import CSS for styling
import { doc, getDoc } from 'firebase/firestore'; // Import necessary functions
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ChatStart = () => {
    const navigate=useHistory();
    const location = useLocation();
    const [user] = useAuthState(auth);
    const queryParams = new URLSearchParams(location.search);
    const contactId = queryParams.get('contact');

    const backToHome=()=>{
        navigate.push('/');
    }
    
    const [realContact, setRealContact] = useState(null);

    const fetchContactData = async (contactId) => {
        const docRef = doc(db, "serviceProviders", contactId);
        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setRealContact(docSnap.data());
            } else {
                console.log("No such document!");
                setRealContact({ name: 'Unknown', profession: 'Unknown', imageUrls: ['defaultImageUrl.jpg'] });
            }
        } catch (error) {
            console.log("Error getting document:", error);
        }
    };

    useEffect(() => {
        if (contactId) {
            fetchContactData(contactId);
        }
    }, [contactId]);

    return (
        <div className="app-container">
            <header className="app-header">
                {realContact ? (
                    <div className="provider-info">
                        <img
                            src={realContact.imageUrls[0]}
                            alt={`Provider Image`}
                            className="profile-pic-large"
                        />
                        <h1>{realContact.name} <p style={{fontSize:20,fontWeight:'lighter'}}>{realContact.profession}</p></h1>
                        
                    </div>
                ) : (
                    <p>Loading ...</p>
                )}

                {user ? <SignOut /> : <SignIn />}
            </header>
            
            <main className="app-main">
                {user ? (
                    <ChatRoom contactId={contactId} />
                ) : (
                    <div>
                    <h2 className="sign-in-prompt">Please sign in to chat with {realContact ? realContact.name : '...'}</h2>
                    <center><button onClick={backToHome} className='btn btn-primary btn-sm'>Service Spotter</button></center>
                    </div>
                )}
            </main>
        </div>
    );
};

const SignIn = () => {
    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
    };

    return (
        <button className="sign-in-button" onClick={signInWithGoogle}>
            Sign in with Google
        </button>
    );
};

const SignOut = () => {
    return (
        <button className="sign-out-button" onClick={() => auth.signOut()}>
            Sign Out
        </button>
    );
};

export default ChatStart;
