// firebaseConfig.js
import { initializeApp } from '@firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAVSiuypKz7yQi1vAZf-LfGdIejtH5J9-g",
    authDomain: "service-spotter.firebaseapp.com",
    projectId: "service-spotter",
    storageBucket: "service-spotter.appspot.com",
    messagingSenderId: "732962752035",
    appId: "1:732962752035:web:37ddaac4c44b92544cd396",
    measurementId: "G-GBL3M7VGER"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize and export Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
